<template>
  <v-container fluid class="pt-0 pay-table-wrapper">
    <v-dialog v-model="document_blocked" persistent width="400">
      <v-card class="pt-4 pb-1">
        <v-card-text style="font-size: 1rem">
          {{ `Цей документ заблоковано користувачем ${document_blocked_data?.user} до ` }}
          <span class="font-weight-bold success--text">{{ document_blocked_data?.blocked_to | formatDate('DD.MM.YYYY HH:mm:ss') }}</span>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="pt-2">
          <v-spacer></v-spacer>
          <v-btn small depressed color="grey darken-2 white--text" @click.stop="goBack()">Повернутися</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row justify="center">
      <v-col cols="12">
        <v-card v-if="!loading">
          <v-toolbar tile elevation="0" outlined dense style="border: none">
            <v-toolbar-title class="font-weight-medium" v-if="$route.params.header_id === 'create'">Внесення оплат
              (створення)
            </v-toolbar-title>
            <v-toolbar-title class="font-weight-medium" v-else>{{
                `Внесення оплат №${$route.params.header_id} від ${formatDate(header_create_date)}`
              }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items class="shrink" v-if="$vuetify.breakpoint.smAndUp">
              <v-row class="align-center">
                <v-btn fab depressed small class="mr-2"
                       :loading="loading_saving"
                       @click="saveDocument" :disabled="!documentReady || header_month !== current_month">
                  <v-icon color="grey darken-1">
                    mdi-content-save
                  </v-icon>
                </v-btn>
                <v-btn fab depressed small
                       :loading="loading_saving"
                       @click.stop="deleteDialog" v-if="header_month === current_month">
                  <v-icon color="error">
                    mdi-delete
                  </v-icon>
                </v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
          <v-card-text class="pt-0">
            <v-row>
              <v-col cols="6" md="3" order-sm="1" :class="$vuetify.breakpoint.xs ? 'pb-0' : ''">
                <v-text-field label="Створено" hide-details readonly
                              :value="header_create_date | formatDate('DD.MM.YYYY HH:mm:ss')"
                              color="grey"
                              required
                              class="custom-text-field"/>
              </v-col>
              <v-col cols="6" md="3" order-sm="2" :class="$vuetify.breakpoint.xs ? 'pb-0 pt-2' : ''">
                <date-component
                    class_="custom-text-field" :filled='false'
                    v-model="header_date" req label="Дата"
                    :placeholder="'Оберіть дату'"
                    :height="56"/>
              </v-col>
              <v-col cols="12" md="2" order-sm="3" order="4" :class="$vuetify.breakpoint.xs ? 'pb-0 pt-2' : ''">
                <v-text-field label="Всього" hide-details readonly v-model="header_total_sum"
                              v-decimal
                              color="grey"
                              class="custom-text-field"
                />
              </v-col>
              <v-col cols="12" md="4" order-sm="4" order="3" :class="$vuetify.breakpoint.xs ? 'pb-0 pt-2' : ''">
                <Bank :value="header_bank"
                      @autocompleteChange="bankChangeHeader"
                      :class="header_bank ? 'custom-text-field' : 'custom-text-field'" color="grey" :height="56"
                      :dense="false"
                      :denseList="true"
                      :required="true"
                      :filled="false"
                      :label="header_by_bank ? 'Банк вказувати не потрібно' : 'Банк'"
                      :disabled="header_by_bank"
                />
              </v-col>
              <v-col cols="3" class="pt-0" order-sm="5" order="5">
                <v-select :items="split_type_select" label="Вид поділу"
                          hide-details
                          v-model="header_split_type"
                          color="grey"
                          class="custom-text-field mt-0"/>
              </v-col>
              <v-col cols="9" class="pt-0" order-sm="5" order="5">
                <v-text-field label="Коментар" hide-details
                              v-model="header_comment"
                              color="grey"
                              class="custom-text-field"/>
              </v-col>
              <v-col cols="9" v-if="$vuetify.breakpoint.xs" order="6">
                <v-btn block depressed small
                       :loading="loading_saving"
                       @click="saveDocument" :disabled="!documentReady || header_month !== current_month">
                  <v-icon small left color="grey darken-1" class="mr-2">
                    mdi-content-save
                  </v-icon>
                  Зберегти
                </v-btn>
              </v-col>
              <v-col cols="3" v-if="$vuetify.breakpoint.xs" order="7">
                <v-btn block depressed
                       :loading="loading_saving"
                       small @click.stop="deleteDialog">
                  <v-icon color="error">
                    mdi-delete
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <template v-else>
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="10">
                  <v-skeleton-loader type="text" width="100%"></v-skeleton-loader>
                </v-col>
                <v-col cols="6" md="3" order-sm="1" :class="$vuetify.breakpoint.xs ? 'pb-0' : ''">
                  <v-skeleton-loader type="text" width="100%"/>
                </v-col>
                <v-col cols="6" md="3" order-sm="2" :class="$vuetify.breakpoint.xs ? 'pb-0 pt-2' : ''">
                  <v-skeleton-loader type="text" width="100%"/>
                </v-col>
                <v-col cols="12" md="2" order-sm="3" order="4" :class="$vuetify.breakpoint.xs ? 'pb-0 pt-2' : ''">
                  <v-skeleton-loader type="text" width="100%"/>
                </v-col>
                <v-col cols="12" md="4" order-sm="4" order="3" :class="$vuetify.breakpoint.xs ? 'pb-0 pt-2' : ''">
                  <v-skeleton-loader type="text" width="100%"/>
                </v-col>
                <v-col cols="12" class="pt-0" order-sm="5" order="5">
                  <v-skeleton-loader type="text" width="100%" height="36px"/>
                </v-col>
                <v-col cols="9" v-if="$vuetify.breakpoint.xs" order="6">
                  <v-skeleton-loader type="button"/>
                </v-col>
                <v-col cols="3" v-if="$vuetify.breakpoint.xs" order="7">
                  <v-skeleton-loader type="button"/>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </template>
      </v-col>
      <v-col cols="12" class="py-1">
        <v-card tile flat color="grey lighten-4" v-if="!loading" class="d-flex">
          <v-btn small depressed color="grey lighten-3" @click="addNewRow" class="mr-2" :disabled="!documentReady">
            <v-icon left size="22">
              mdi-plus
            </v-icon>
            Додати рядок
          </v-btn>
          <v-btn small depressed color="error lighten-3" @click="deleteRows" class="mr-2"
                 :disabled="!selectedRows.length">
            <v-icon left size="22">
              mdi-delete
            </v-icon>
            Видалити рядок(-ки)
          </v-btn>
          <v-text-field class="pl-4 pr-3 pt-0 mt-0" v-model="search"
                        hide-details placeholder="Введіть текст для пошуку"
                        color="grey darken-1" clearable clear-icon="mdi-close"
                        prepend-icon="mdi-text-box-search-outline"
                        @input="searchByText"
          />
        </v-card>
        <v-card v-else class="d-flex px-2 py-3">
          <div class="mr-2">
            <v-skeleton-loader type="button"/>
          </div>
          <div class="mr-2">
            <v-skeleton-loader type="button"/>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card class="fill-height" style="overflow-x: auto;" v-if="!loading">
          <div class="pay-table">
            <div class="pay-table-header">
              <div class="pay-table-row" :class="$vuetify.breakpoint.xs ? 'xs-row' : ''">
                <div class="pay-table-col"></div>
                <div class="pay-table-col">
                  №
                </div>
                <div class="pay-table-col editable">
                  Особовий
                </div>
                <div class="pay-table-col">
                  Адреса
                </div>
                <div class="pay-table-col">
                  Платник
                </div>
                <div class="pay-table-col editable">
                  Дата
                </div>
                <div class="pay-table-col">
                  Банк
                </div>
                <div class="pay-table-col">
                  Сальдо
                </div>
                <div class="pay-table-col editable">
                  Оплачено
                </div>
                <div class="pay-table-col editable">
                  Комісія
                </div>
                <div class="pay-table-col editable">
                  Послуга
                </div>
              </div>
            </div>
            <div class="pay-table-body">
              <div
                  class="pay-table-row"
                  :class="{'expandable': item.services.length || item.service_id === all_services,
                           'expanded': item.row_num === expand_row_num}"
                  v-for="(item, index) in filtered_items" :key="`item-${item.row_num}-${index}`">
                <template v-if="!item.services.length && !(item.service_id === all_services)">
                  <div class="pay-table-col" style="border-left: 6px solid #4caf50d1; padding-left: 10px !important;">
                    <v-checkbox class="select-checkbox" color="success" hide-details :ripple="false"
                                @change="selectRow(item.row_num)"></v-checkbox>
                  </div>
                  <div class="pay-table-col">
                    {{ item.row_idx }}
                  </div>
                  <div class="pay-table-col editable">
                      <AUC_FlatSearch
                              :row_num="item.row_num"
                              :flat_id="item.flat_id"
                              :text_value="item.person_id"
                              :text_value_key="'person_id'"
                              :class="{'error-input': !item.person_id}"
                              @selectChange="flatChange"
                      />
                  </div>
                  <div class="pay-table-col">
                    <input type="text" v-model="item.address">
                  </div>
                  <div class="pay-table-col">
                    {{ item.payer }}
                  </div>
                  <div class="pay-table-col editable" style="position:relative;">
                    <input type="text" :value="item.pay_date | formatDate" readonly
                           :class="{'error-input': !item.pay_date}">
                    <div style="position:absolute; right: 18%; top: 9px">
                      <DateComponentIcon v-model="item.pay_date" :row_num="item.row_num" @changeDate="changeDate"/>
                    </div>
                  </div>
                  <div class="pay-table-col editable">
                    <Bank
                        :value="item.bank"
                        :row_num="item.row_num"
                        :filled="false"
                        :label="''"
                        :autosync="false"
                        :clearable="false"
                        @autocompleteChange="bankChange"
                        :class="{'error-input': !item.bank, 'table-input': true}"
                        color="grey"/>
                  </div>
                  <div class="pay-table-col" :class="{'negative-balance': item.balance < 0,
                                                      'positive-balance': item.balance > 0,
                                                      'equal-balance': item.balance === 0
                                                      }"
                  >
                    {{ item.balance | formatToFixed }}
                  </div>
                  <div class="pay-table-col editable">
                    <input type="text"
                           :value="item.sum"
                           @input="item.sum = +$event.target.value || 0"
                           :class="{'error-input': !item.sum}"
                           @change="markRowEdited(item.row_num)"
                           v-decimal>
                  </div>
                  <div class="pay-table-col editable">
                    <input type="text"
                           :value="item.commission"
                           @input="item.commission = +$event.target.value || 0"
                           @change="markRowEdited(item.row_num)"
                           v-decimal>
                  </div>
                  <div class="pay-table-col editable">
                    <v-icon style="width: 28px" :color="getAccountIcon(item.icon, 'color')" size="24" class="mr-3">
                      {{ getAccountIcon(item.icon, 'icon') }}
                    </v-icon>
                    <div style="line-height: 25px; width: 220px"
                         :class="{'error-input-select': !item.service_id, 'input-select': item.service_id}">
                      <v-select :items="services"
                                hide-details
                                v-model="item.service_id"
                                :class="{'error-input': !item.service_id, 'table-input': true}"
                                @change="changeService($event, item.row_num, false)"
                                color="grey"/>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="pay-table-row-expand-header" :class="{'expanded': item.row_num === expand_row_num}">
                    <div class="pay-table-col" style="border-left: 6px solid #737373; padding-left: 10px !important;">
                      <v-checkbox class="select-checkbox"
                                  color="success" hide-details
                                  :ripple="false"
                                  @change="selectRow(item.row_num)"
                      />
                    </div>
                    <div class="pay-table-col">
                      {{ item.row_idx }}
                    </div>
                    <div class="pay-table-col editable">
                        <AUC_FlatSearch
                                :row_num="item.row_num"
                                :flat_id="item.flat_id"
                                :text_value="item.person_id"
                                :text_value_key="'person_id'"
                                :class="{'error-input': !item.person_id}"
                                @selectChange="flatChange"
                        />
                    </div>
                    <div class="pay-table-col">
                      <input type="text" v-model="item.address">
                    </div>
                    <div class="pay-table-col">
                      {{ item.payer }}
                    </div>
                    <div class="pay-table-col editable" style="position:relative;">
                      <input type="text" :value="item.pay_date | formatDate" readonly
                             :class="{'error-input': !item.pay_date}">
                      <div style="position:absolute; right: 18%; top: 9px">
                        <DateComponentIcon
                            v-model="item.pay_date"
                            :row_num="item.row_num"
                            @changeDate="changeDate"
                        />
                      </div>
                    </div>
                    <div class="pay-table-col editable">
                      <Bank
                          :value="item.bank"
                          :row_num="item.row_num"
                          :filled="false"
                          :label="''"
                          :clearable="false"
                          :autosync="false"
                          @autocompleteChange="bankChange"
                          :class="{'error-input': !item.bank, 'table-input': true}"
                          color="grey"/>
                    </div>
                    <div class="pay-table-col" :class="{'negative-balance': item.balance < 0,
                                                      'positive-balance': item.balance > 0,
                                                      'equal-balance': item.balance === 0
                                                      }"
                    >
                      {{ item.balance | formatToFixed }}
                    </div>
                    <div class="pay-table-col editable">
                      <input
                          type="text"
                          :value="item.sum"
                          @input="item.sum = +$event.target.value || 0"
                          @change="changeSum(item.row_num, null, item.sum, item.commission)"
                          :class="{'error-input': !item.sum || item.error}"
                          v-decimal>
                    </div>
                    <div class="pay-table-col editable">
                      <input
                          type="text"
                          :value="item.commission"
                          @input="item.commission = +$event.target.value || 0"
                          @change="changeSum(item.row_num, null, item.sum, item.commission)"
                          v-decimal>
                    </div>
                    <div class="pay-table-col editable">
                      <v-btn icon small class="mr-3" @click="expandRow(item.row_num)">
                        <v-icon>
                          {{
                            item.row_num === expand_row_num ? 'mdi-arrow-up-bold-circle-outline' : 'mdi-arrow-down-bold-circle-outline'
                          }}
                        </v-icon>
                      </v-btn>
                      <div style="line-height: 25px; max-width: 220px">
                        <v-select :items="services"
                                  hide-details
                                  v-model="item.service_id"
                                  class="table-input"
                                  :class="{'error-input': !item.service_id}"
                                  @change="changeService($event, item.row_num, false)"
                                  color="grey"/>
                      </div>
                    </div>
                  </div>
                  <div class="pay-table-row-expand-body" :class="{'expanded': item.row_num === expand_row_num}">
                    <div
                        class="pay-table-expand-body-row"
                        v-for="(sub_item, sub_idx) in item.services"
                        :key="`sub-item-${item.row_num}-${sub_idx}`">
                      <div class="pay-table-col"></div>
                      <div class="pay-table-col" :class="{'negative-balance': sub_item.balance < 0,
                                                      'positive-balance': sub_item.balance > 0,
                                                      'equal-balance': sub_item.balance === 0
                                                      }"
                      >
                        {{ sub_item.balance | formatToFixed }}
                      </div>
                      <div class="pay-table-col">
                        <input
                            type="text"
                            :value="sub_item.sum"
                            :class="{'error-input': item.error}"
                            v-decimal
                            @input="subitemInput($event, sub_item, item)"
                            @change="changeSum(item.row_num, 1, sub_item.sum, sub_item.commission)"
                        >
                      </div>
                      <div class="pay-table-col">
                        <input
                            type="text"
                            :value="sub_item.commission"
                            :class="{'error-input': item.error}"
                            v-decimal
                            @input="subitemCommissionInput($event, sub_item, item)"
                            @change="changeSum(item.row_num, 1, sub_item.sum, sub_item.commission)"
                        >
                      </div>
                      <div class="pay-table-col">
                        <div>
                          <v-icon style="width: 28px" :color="getAccountIcon(sub_item.icon, 'color')" size="20"
                                  class="mr-3">
                            {{ getAccountIcon(sub_item.icon, 'icon') }}
                          </v-icon>
                        </div>
                        <div style="line-height: 25px; width: 220px"
                             :class="{'error-input-select': !sub_item.service_id}">
                          <v-select :items="services"
                                    hide-details
                                    v-model="sub_item.service_id"
                                    class="table-input"
                                    readonly
                                    @change="changeService($event, item.row_num, true)"
                                    color="grey"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
            <div class="pay-table-pagination d-flex justify-start" v-if="table_items.length">
              <div class="pa-3 d-flex align-center justify-start">
                <v-btn text depressed icon class="mr-1"
                       @click.stop="pageStartOrEnd(true)"
                       :disabled="items_per_page > table_items.length || page === 1"
                >
                  <v-icon>mdi-chevron-double-left</v-icon>
                </v-btn>
                <v-btn text depressed icon
                       @click.stop="changePage(true)"
                       :disabled="page === 1"
                >
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <div class="mx-2">
                  <v-chip>
                    {{ `Сторінка: ${page} із ${pages}` }}
                  </v-chip>
                </div>
                <v-menu top :close-on-click="true" :close-on-content-click="true">
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip v-bind="attrs" v-on="on" class="mr-2">
                      {{ `${items_per_page} на стор.` }}
                    </v-chip>
                  </template>
                  <v-list>
                    <v-list-item
                        v-for="(item, index) in items_per_page_list"
                        :key="index"
                        @click="setItemsPerPage(item)"
                    >
                      <v-list-item-title>{{ item }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-btn text depressed icon class="mr-1"
                       @click.stop="changePage(false)"
                       :disabled="items_per_page > table_items.length || page === pages"
                >
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
                <v-btn text depressed icon
                       @click.stop="pageStartOrEnd(false)"
                       :disabled="page === pages"
                >
                  <v-icon>mdi-chevron-double-right</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
          <div v-if="!loading && !table_items.length" class="table-no-data">
            Для внесення оплат скористайтесь командою "Додати рядок"
          </div>
        </v-card>
        <template v-else>
          <v-skeleton-loader
              height="100%"
              style="height: 100%"
              type="table"
          ></v-skeleton-loader>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  getAccountIcon,
  split_type_select,
  getCurrentDate,
  beginOfMonth,
  endOfMonth
} from "@/utils/icons";
import paymentAPI from '@/utils/axios/payments'
import {ALERT_SHOW} from "@/store/actions/alert";
import {mapGetters} from "vuex";
import {maxBy} from 'lodash'
import {formatDate} from "@/filters";
import {QUESTION_SHOW} from "@/store/actions/question";
import clockAPI from "@/utils/axios/clock"

const modalDeleteId = 'payment_header_modal_delete'

export default {
  name: "DocumentPayHeader",
  components: {
    DateComponentIcon: () => import("@/components/tableComponent/DateComponentIcon"),
    AUC_FlatSearch: () => import("@/components/tableComponent/AUC_FlatSearch.vue"),
    Bank: () => import("@/components/autocomplite/Bank")
  },
  computed: {
    ...mapGetters({
      services: 'getServicesSelect',
      current_month: 'getCurrentMonth',
      settings: 'getGeneralSettings',
      modalAnswer: 'question_answer',
    }),
    all_services() {
      return (this.settings.all_services || {}).value || 0
    },
    documentReady() {
      let errors = 0
      this.table_items.forEach(item => {
        if (item.error) {
          errors += 1
        }
        if (!item.flat_id || !item.service_id || !item.bank) {
          errors += 1
        }
      })

      if (!this.header_bank && !this.header_by_bank) {
        errors += 1
      }

      if (!this.header_date) {
        errors += 1
      }

      return !errors
    },
    filtered_items() {
      if (this.search) {
        const items = this.table_items.filter(item => {
          return item.address.toLowerCase().indexOf(this.search.toLowerCase()) !== -1 ||
              item.person_id.indexOf(this.search.toLowerCase()) !== -1 ||
              item.sum.toString().indexOf(this.search.toLowerCase()) !== -1 ||
              item.payer.toLowerCase().indexOf(this.search.toLowerCase()) !== -1
        })
        return items.slice((this.page - 1) * this.items_per_page, (this.page - 1) * this.items_per_page + this.items_per_page)
      } else {
        return this.table_items.slice((this.page - 1) * this.items_per_page, (this.page - 1) * this.items_per_page + this.items_per_page)
      }
    }
  },
  data() {
    return {
      search: '',
      document_blocked: false,
      document_blocked_timeout: null,
      document_blocked_data: null,
      loading: false,
      showConfirm: true,
      expand_row_num: 0,
      errors: [],
      selectedRows: [],
      table_items: [],
      dataLoaded: false,
      loading_saving: false,
      header_date: '2020-01-01',
      header_create_date: '2020-01-15',
      header_bank: null,
      header_total_sum: 1000.00,
      header_by_bank: null,
      header_month: null,
      header_comment: '',
      delete_watcher: null,
      header_split_type: null,
      pages: 0,
      items_per_page: 13,
      page: 0,
      items_per_page_list: [10, 12, 14, 16, 18, 20, 25, 50],
      items_crud: {
        deleted: [],
        edited: [],
        added: [],
        manual: []
      },
      sorted_items: [
        {text: 'Дата створення', value: 'Дата оплати'},
        {text: 'Дата оплати', value: 'Дата оплати'},
        {text: 'Банк', value: 'bank_name'},
      ],
      split_type_select
    }
  },
  methods: {
    searchByText() {
      this.setPageSplitter()
    },
    changePage(left) {
      if (left) {
        this.page = this.page === 1 ? 1 : this.page - 1
      } else {
        this.page = this.page === this.pages ? this.pages : this.page + 1
      }
    },
    setPageSplitter() {
      if (this.search) {
        const len = this.table_items.filter(item => {
          return item.address.toLowerCase().indexOf(this.search.toLowerCase()) !== -1 ||
              item.person_id.indexOf(this.search.toLowerCase()) !== -1 ||
              item.sum.toString().indexOf(this.search.toLowerCase()) !== -1 ||
              item.payer.toLowerCase().indexOf(this.search.toLowerCase()) !== -1
        }).length
        this.pages = Math.ceil(len / this.items_per_page)
        this.page = this.pages ? 1 : 0
      } else {
        this.pages = Math.ceil(this.table_items.length / this.items_per_page)
        this.page = this.pages ? 1 : 0
      }

      if (this.pages * this.items_per_page - this.items_per_page > this.table_items.length) {
        this.pages -= 1
      }
    },
    setItemsPerPage(per_page) {
      this.items_per_page = per_page
      this.setPageSplitter()
    },
    pageStartOrEnd(start) {
      if (start) {
        this.page = 1
      } else {
        this.page = this.pages
      }
    },
    formatDate,
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення документа внесення оплат № ${this.$route.params.header_id}`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.loading_saving = true
                  paymentAPI.remove(+this.$route.params.header_id)
                      .then(response => response.data)
                      .then(() => {
                        this.$store.dispatch(ALERT_SHOW, {text: 'Документ вилучено успішно', color: 'success'})
                        this.$router.push({name: 'PaymentDocuments'})
                      })
                      .catch(err => {
                        const error = err.response.data.detail;
                        this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                      })
                      .finally(() => {
                        this.loading_saving = false
                      })
                }
              }
            }
          }
      )
    },
    getAccountIcon,
    expandRow(row_num) {
      const row = this.table_items.find(item => item.row_num === row_num)
      if (row) {
        if (row.service_id === this.all_services && !row.services.length) {
          this.changeService(this.all_services, row_num)
        }
      }
      this.expand_row_num = this.expand_row_num === row_num ? 0 : row_num
    },
    selectRow(row_num) {
      if (row_num) {
        if (this.selectedRows.indexOf(row_num) > -1) {
          this.selectedRows = this.selectedRows.filter(item => item !== row_num)
        } else {
          this.selectedRows.push(row_num)
        }
      }
    },
    changeDate(payload) {
      if (payload) {
        const row = this.table_items.find(item => item.row_num === payload.row_num)
        if (row) {
          row.pay_date = payload.value
        }
        this.markRowEdited(payload.row_num)
      }
    },
    getDefaultService() {
      // if (this.header_split_type === 'dont_split') {
      //   return this.settings?.default_service?.value || null
      // }
      // if (this.header_split_type === 'all_service') {
      //   return this.settings?.all_service?.value || null
      // }
      return this.settings?.default_service?.value || null
    },
    flatChange(payload) {
      const current_row = this.table_items.find(item => item.row_num === payload.row_num)
      if (current_row) {
        current_row.address = payload.address || ''
        current_row.person_id = payload.person_id
        current_row.payer = (payload.flat_owner || '').trim()
        current_row.flat_id = payload.value
        current_row.service_id = this.getDefaultService()
        if (current_row.service_id) {
          this.changeService(current_row.service_id, current_row.row_num)
        }
        this.markRowEdited(payload.row_num)
      }
    },
    getTotals() {
      const header_total_sum = this.table_items.reduce((acc, item) => {
        return acc + item.sum
      }, 0)
      this.header_total_sum = (+header_total_sum.toFixed(2));
    },
    changeSum(row_num, id, sum, commission = 0) {
      this.markRowEdited(row_num)

      if (!id) {
        this.splitSum(row_num, sum, commission)
      } else {
        this.checkSumForSubItems(row_num)
      }
      this.getTotals()
    },
    subitemInput(e, sub_item, item) {
      sub_item.sum = +e.target.value || 0
      item.edited = true
    },
    subitemCommissionInput(e, sub_item, item) {
      sub_item.commission = +e.target.value || 0
      item.edited = true
    },
    doSplit(iterable, sum, commission, operation = 'positive') {
      let sumForSplit = sum
      let sumForSplitCommission = commission || 0
      let total = 0
      let assign = operation === 'negative' ? -1 : 1
      let add = operation === 'equal' ? 100 : 0

      const func = {
        ['positive']: item => item.balance > 0,
        ['negative']: item => item.balance < 0,
        ['equal']: item => item.balance === 0
      }

      iterable.forEach(item => {
        item.sum = 0
        item.commission = 0
      })

      const filter_array = iterable.filter(func[operation]).map(item => {
        total += item.balance * assign + add
        return {id: item.id, balance: item.balance * assign + add, result: 0}
      })

      if (operation === 'negative') {
        const reversed = filter_array.sort((a, b) => a.balance - b.balance).reverse()

        reversed.forEach((item, index) => {
          filter_array[index].id = item.id
        })
      }

      filter_array.forEach(item => {
        const k = sumForSplit / total
        const c = sumForSplitCommission ? sumForSplitCommission / total : 0
        item.result = +(item.balance * k).toFixed(2) || 0
        item.result_commission = +(item.balance * c).toFixed(2) || 0

        sumForSplit -= item.result
        sumForSplitCommission -= item.result_commission
        total -= item.balance

        const i = iterable.find(it => it.id === item.id)
        if (i) {
          i.sum = item.result
          i.commission = item.commission
        }
      })

      sumForSplit = +(sumForSplit.toFixed(2))
      sumForSplitCommission = +(sumForSplitCommission.toFixed(2))

      return sumForSplit
    },
    splitSum(row_num, sum, commission) {
      const current_row = this.table_items.find(item => item.row_num === row_num)
      let sumForSplit = this.doSplit(current_row.services, sum, commission, 'positive')
      if (sumForSplit > 0) {
        sumForSplit = this.doSplit(current_row.services, sum, commission, 'negative')
      }
      if (sumForSplit > 0) {
        sumForSplit = this.doSplit(current_row.services, sum, commission, 'equal')
      }
      current_row.edited = false
      this.checkSumForSubItems(row_num)
    },
    bankChange(payload) {
      if (payload) {
        const row = this.table_items.find(item => item.row_num === payload.row_num)
        if (row) {
          row.bank = payload.value
        }
        this.markRowEdited(payload.row_num)
      }
    },
    bankChangeHeader(payload) {
      this.header_bank = payload.value
    },
    changeService(service_id, row_num) {
      const row = this.table_items.find(item => item.row_num === row_num)
      const all_service_id = (this.settings.all_services || {}).value || 0

      if (row) {
        const service_row = this.services.find(item => item.value === service_id)
        row.edited = false

        if (service_row) {
          row.icon = service_row.type
        }

        if (row.services.length && service_id === all_service_id) {
          this.splitSum(row_num, row.sum, row.commission)
        } else if (row.services.length && service_id !== all_service_id) {
          row.services = []
        } else if (!row.services.length && service_id === all_service_id) {
          this.get_services_for_split({header_id: 1, flat_id: row.flat_id})
              .then(response => {
                if (response.length === 0) {
                  row.services = []
                  row.service_id = this.getDefaultService()
                } else {
                  row.services = response
                }
                this.splitSum(row_num, row.sum, row.commission)
              })
              .catch(() => {
                row.services = [
                  {
                    id: 0,
                    pay_date: this.header_date,
                    service_id: 0,
                    bank: this.header_bank,
                    sum: 0,
                    commission: 0,
                    balance: 0,
                    icon: ''
                  }
                ]
              })
              .finally(() => {
                this.splitSum(row_num, row.sum, row.commission)
              })
        }
      }
      this.getTotals()
      this.markRowEdited(row_num)
    },
    addNewRow() {
      const max_row_item = maxBy(this.table_items, 'row_num')
      const max_row_idx = maxBy(this.table_items, 'row_idx')

      let max_row = 0
      if (max_row_item) {
        max_row = max_row_item.row_num
      }

      let max_idx = 0
      if (max_row_idx) {
        max_idx = max_row_idx.row_idx
      }


      const new_row = {
        id: 0,
        flat_id: 0,
        pay_date: this.header_date || null,
        sum: 0,
        bank: this.header_bank || null,
        payer: "",
        service_id: null,
        is_group: false,
        services: [],
        balance: 0,
        icon: "",
        person_id: "",
        address: "",
        row_num: max_row + 1,
        row_idx: max_idx + 1,
        error: false,
        edited: false
      }
      this.table_items.push(new_row)
      this.items_crud.added.push(new_row.row_num)
      this.setPageSplitter()
      this.page = this.pages
    },
    deleteRows() {
      this.selectedRows.forEach(item => {
        const tab_item = this.table_items.find(tab_item => tab_item.row_num === item)
        if (tab_item) {
          const idx = this.table_items.indexOf(tab_item)
          const added = this.items_crud.added.includes(tab_item.row_num)
          if (!added) {
            if (tab_item.id) {
              this.items_crud.deleted.push(tab_item.id)
            }
          } else {
            const add_idx = this.items_crud.added.indexOf(tab_item.row_num)
            this.items_crud.added.splice(add_idx, 1)
          }
          this.table_items.splice(idx, 1)
        }
      })
      this.table_items.forEach((item, idx) => {
        item['row_idx'] = idx + 1
      })
      this.selectedRows = []
      this.getTotals()
    },
    get_services_for_split(payload) {
      return new Promise((resolve, reject) => {
        paymentAPI.get_services_for_sub_item(payload)
            .then(response => response.data)
            .then(data => {
              let idx = 0
              data.forEach(item => {
                idx += 1
                if (item.id === 0) {
                  item.pay_date = this.header_date
                  item.bank = this.header_bank
                  item.id = idx
                }
              })
              resolve(data)
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
              reject(false)
            })
      })
    },
    checkSumForSubItems(row_num) {
      const row = this.table_items.find(item => item.row_num === row_num)
      if (row) {
        const child_sum = row.services.reduce((total, el) => {
          return el.sum + total
        }, 0)
        row.error = row.sum !== (+child_sum.toFixed(2));
      }
    },
    markRowEdited(row_num) {
      const added = this.items_crud.added.includes(row_num)
      const edited = this.items_crud.edited.includes(row_num)

      if (!added && !edited) {
        this.items_crud.edited.push(row_num)
      }
      this.getTotals()
    },
    constructRowsForSave(row_type) {
      if (row_type === 'added' || row_type === 'edited') {
        return this.items_crud[row_type].map(item => {
          const row = this.table_items.find(i => i.row_num === item)
          let sub_services = row.services.map(s => {
            return `[${s.service_id},${s.sum || 0},${s.commission || 0}]`
          })

          if (!row.edited) {
            return {
              header_id: 1,
              flat_id: row.flat_id,
              pay_date: row.pay_date,
              sum: row.sum,
              bank: row.bank,
              payer: row.payer,
              service_id: row.service_id,
              is_group: false,
              edited: row.edited || false,
              sub_services: sub_services.length ? sub_services : null,
              services: [],
              id: row.id,
            }
          } else {
            return {
              header_id: 1,
              flat_id: row.flat_id,
              pay_date: row.pay_date,
              sum: row.sum,
              bank: row.bank,
              payer: row.payer,
              service_id: row.service_id,
              is_group: false,
              edited: row.edited || false,
              sub_services: sub_services.length ? sub_services : null,
              services: row.services.map(s => {
                return {service_id: s.service_id, sum: s.sum}
              }),
              id: row.id,
            }
          }
        })
      }

      if (row_type === 'deleted') {
        return this.items_crud.deleted.map(item => {
          return {id: item}
        })
      }
    },
    saveDocument() {
      const is_create = this.$route.params.header_id === 'create'

      const payload = {
        header: {
          pay_date: this.header_date,
          total_sum: this.header_total_sum,
          total_row: this.table_items.length,
          bank: this.header_bank,
          by_bank: this.header_by_bank,
          date: this.header_create_date,
          month: this.header_month,
          comment: this.header_comment,
          split_type: this.header_split_type
        },
      }
      if (!is_create) {
        payload['table_added'] = this.constructRowsForSave('added')
        payload['table_edited'] = this.constructRowsForSave('edited')
        payload['table_deleted'] = this.constructRowsForSave('deleted')
        payload.header['id'] = +this.$route.params.header_id
      } else {
        payload['table'] = this.constructRowsForSave('added')
      }

      if (is_create) {
        this.loading_saving = true
        paymentAPI.create(payload)
            .then(response => response.data)
            .then(data => {
              if (data) {
                const header_id = +data
                this.showConfirm = false
                this.$store.commit(ALERT_SHOW, {text: 'Оплати успішно створено', color: 'success'})
                this.items_crud.manual = []
                this.items_crud.added = []
                this.items_crud.edited = []
                this.items_crud.deleted = []
                if (!this.document_blocked_timeout) {
                  this.blockTimeout()
                }
                this.$router.replace(
                    {
                      name: 'PaymentDocumentHeader',
                      params: {header_id: header_id}
                    }
                )
              }
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
            .finally(() => {
              this.loading_saving = false
            })
      } else {
        this.loading_saving = true
        paymentAPI.update(payload)
            .then(response => response.data)
            .then(data => {
              if (data) {
                this.items_crud.deleted = []
                this.items_crud.edited = []
                this.items_crud.added = []
                this.items_crud.manual = []
                this.$store.commit(ALERT_SHOW, {text: 'Оплати успішно збережені', color: 'success'})
              }
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
            .finally(() => {
              this.loading_saving = false
            })
      }
    },
    blockTimeout() {
      const vm = this
      const is_create = this.$route.params.header_id === 'create'
      if (!is_create) {
        this.document_blocked_timeout = setInterval(() => {
          vm.blockDocument()
        },23000)
      }
    },
    blockDocument(header_id=null) {
      let local_header_id = header_id
      if (!local_header_id) {
        const is_created = this.$route.params.header_id !== 'create'
        if (is_created) {
          local_header_id = +this.$route.params.header_id
        }
      }
      if (local_header_id) {
        clockAPI.block({
          object_name: 'document_payment',
          object_id: local_header_id
        })
            .then(response => response.data)
            .then(data => {
              const prev_block = this.document_blocked
              this.document_blocked = data.blocked
              this.document_blocked_data = data

              if (prev_block === true && this.document_blocked === false) {
                this.renew_document()
                this.document_blocked_timeout = null
                this.blockDocument()
                this.blockTimeout()
              }
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
            })
      }
    },
    unblockDocument(header_id=null) {
      let local_header_id = header_id
      if (!local_header_id) {
        const is_created = this.$route.params.header_id !== 'create'
        if (is_created) {
          local_header_id = +this.$route.params.header_id
        }
      }
      if (local_header_id) {
        clockAPI.unblock({
          object_name: 'document_payment',
          object_id: local_header_id
        })
        .then(response => response.data)
        .then(() => {
          this.document_blocked = false
          this.document_blocked_data = null
        })
        .catch(err => {
          const error = err.response.data.detail;
          this.$store.commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
        })
      }
    },
    goBack() {
      this.showConfirm = false
      history.back()
    },
    renew_document(block=false) {
      const is_created = this.$route.params.header_id !== 'create'
      if (is_created) {
        this.loading = true
        const header_id = +this.$route.params.header_id
        paymentAPI.get_by_header(header_id)
            .then(response => response.data)
            .then(data => {
              this.pages = 0
              this.page = 0
              if (data) {
                const header = data.header

                this.header_create_date = header.date
                this.header_bank = header.bank
                this.header_date = header.pay_date
                this.header_total_sum = header.total_sum
                this.header_by_bank = header.by_bank
                this.header_month = header.month
                this.header_comment = header.comment
                this.header_split_type = header.split_type
                const rows = data.rows

                this.table_items = rows.map((item, idx) => {
                  return Object.assign(item, {row_num: idx + 1, row_idx: idx + 1, error: false})
                })
                this.setPageSplitter()
              }
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
            .finally(() => {
              this.loading = false
              if (block) {
                this.blockDocument(header_id)
                if (!this.document_blocked_timeout) {
                  this.blockTimeout()
                }
              }
            })
      }
    }
  },
  created() {
    this.watch_modal_answer()
    if (this.$route.params.header_id) {
      const is_created = this.$route.params.header_id !== 'create'
      this.showConfirm = true

      if (is_created) {
        this.renew_document(true)
      } else {
        let current_date = getCurrentDate()
        let current_date_month = beginOfMonth(current_date)
        const month = this.$route.query.month
        if (month === current_date_month) {
          this.header_date = current_date
        } else {
          this.header_date = endOfMonth(month)
        }
        this.header_create_date = formatDate(Date(), 'YYYY-MM-DD HH:mm:ss')
        this.header_total_sum = 0
        this.header_month = month
        this.header_bank = 0
        this.header_by_bank = false
        this.header_split_type = this.settings?.split_type_by_manual?.value || null
        this.loading = false
      }
    }
  },
  beforeDestroy() {
    if (this.delete_watcher) {
      this.delete_watcher()
    }
    if (this.document_blocked_timeout) {
      clearInterval(this.document_blocked_timeout)
      this.unblockDocument()
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.showConfirm) {
      const answer = window.confirm('Ви дійсно хочете закрити поточну сторінку. Незбережені дані будуть втрачені')
      if (answer) {
        clearInterval(this.document_blocked_timeout)
        this.unblockDocument()
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  }
}
</script>

<style scoped lang="scss">
//must have
.pay-table-col {
  //padding: 10px 2px;

  &:nth-child(1) {
    flex: 0 1 50px;
    padding-left: 16px !important;
    min-width: 50px;
  }

  &:nth-child(2) {
    flex: 0 1 50px;
    min-width: 50px;
    padding-top: 12px;
  }

  &:nth-child(3) {
    flex: 0 1 90px;
    min-width: 90px;
    width: 96% !important;
  }

  &:nth-child(4) {
    flex: 0 1 250px;
    min-width: 190px;
    font-size: 0.74rem !important;

    input {
      border-bottom: none !important;
      width: 98% !important;
    }
  }

  &:nth-child(5) {
    flex: 0 1 130px;
    min-width: 110px;
    padding-top: 10px
  }

  &:nth-child(6) {
    flex: 0 1 115px;
    min-width: 115px;
  }

  &:nth-child(7) {
    flex: 0 1 190px;
    min-width: 150px;
  }

  &:nth-child(8) {
    flex: 0 1 90px;
    min-width: 90px;
    padding-top: 10px
  }

  &:nth-child(9) {
    flex: 0 1 100px;
    min-width: 100px;
  }

  &:nth-child(10) {
    flex: 0 1 80px;
    min-width: 80px;
  }

  &:nth-child(11) {
    flex: 1;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    min-width: 180px;
  }
}

//must have
.pay-table-row-expand-body {
  display: none;
  max-height: 0;

  .pay-table-expand-body-row {
    .pay-table-col {
      &:nth-child(1) {
        flex: 0 1 875px;
        min-width: 755px;
        font-size: 0.8rem !important;
      }

      &:nth-child(2) {
        flex: 0 1 90px;
        border-top: var(--pt-border-width) solid var(--expand-border-color);
        min-width: 90px;
      }

      &:nth-child(3) {
        flex: 0 1 100px;
        border-top: var(--pt-border-width) solid var(--expand-border-color);
        //background-color: $expand-background-col;
        min-width: 100px;
      }

      &:nth-child(4) {
        flex: 0 1 80px;
        border-top: var(--pt-border-width) solid var(--expand-border-color);
        min-width: 80px;

        input {
          border-bottom: var(--pt-border-width) solid var(--pt-border-color) !important;
          padding-bottom: 3px !important;
        }
      }

      &:nth-child(5) {
        flex: 1;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        border-top: var(--pt-border-width) solid var(--expand-border-color);
        min-width: 180px;
      }
    }
  }
}
</style>